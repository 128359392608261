import React from 'react';
import { Link } from '@thd-olt-component-react/router';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
  return (
    <div className="grid isBound u--padding">
      <Helmet>
        <title>Home Page</title>
        <link
          href="https://www.homedepot.com/"
          rel="canonical"
        />
      </Helmet>
      <ul>
        <li><a href="/">Home</a></li>
        <li><Link to="/c/tool_and_truck_rental">Tool and Truck Rental</Link></li>
        <li><Link to="/c/tool_savings">Tool Savings</Link></li>
        <li><Link to="/c/Savings_Center">Specials &amp; Offers [w/ Special Buy Banner]</Link></li>
        <li><Link to="/c/diy_projects_and_ideas">DIY Projects and Ideas [w/ example of Sponsored Banner]</Link></li>
        <li><Link to="/c/collegegameday">College GameDay [w/ example of Sponsored Content]</Link></li>
      </ul>
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};
